<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <el-row>
        <el-col :span="12">
          <el-input
              placeholder="请输入课程名称或课程标签进行查询"
              prefix-icon="el-icon-search"
              v-model="search.keyword">
          </el-input>

        </el-col>
        <el-col :span="12">
          <el-button type="primary" style="margin-left: 20px;" @click="searchAction">检索</el-button>
        </el-col>
      </el-row>
      <div>
        <div v-if="((undefined != projects) && (null != projects) && (projects.length > 0))"  style="margin-top: 20px;">
          <el-row  style="margin: 60px 0;">
            <el-col :span="8" style="height: 262px;position:relative;background: #F9F9F9;border-radius: 10px; padding: 5px;margin-top: 10px;"  v-for="(item, index) in projects" :key="item.id" >
              <div @click="gotoVideoDetail(item.id)">
                <div :style="'background-image: url('+ projects[index].coverPath.url+');no-repeat center top;background-size: 100% 100%; height: 176px;border-radius: 10px 10px 0 0;cursor: pointer;'"></div>
                <div style="position: absolute; background-image: url('../../assets/img/play.png');width: 66px; height: 66px;left: 40%; margin-top: 52px;cursor: pointer;"></div>
                <el-row>
                  <el-col :span="18">
                    <div class="course-title" style="padding-left:15px;font-size: 16px">{{item.name}}</div>
<!--                    <div class="ellipsis-multiline" style="padding-left:15px;font-size: 14px;text-indent: 2em;">{{item.synopsis}}</div>-->
                  </el-col>
                  <el-col :span="6">
                    <span style="float: right;margin-right: 10px;font-size: 16px;">{{ item.leader }}</span>
                    <span  style="color: #fe733d;float: right;line-height: 36px;">观看次数：{{item.likeTimes}}</span>
                  </el-col>
                </el-row>
              </div>

            </el-col>
          </el-row>
        </div>
        <div v-else class="row justify-content-center" style="text-align: center;margin-top: 100px;">
          <div class="col-12">
            <img src="../../assets/img/empty.png">
            <div style="margin-top:12px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;margin-top: 9px;">未找到满足条件的项目信息</div>
          </div>
        </div>
        <el-pagination style="text-align: center;" class="c-pagination" @size-change="changePaginationSize" @current-change="changePaginationCurrent" :current-page="grid.pagination.current"
                       :page-sizes="grid.pagination.pageSizeOptions" :page-size="grid.pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="grid.pagination.total" :hide-on-single-page="true">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      input:'',
      projects:[],
      search:{
        keyword:'',
      },
      grid:{
        items:[],
        selections:[],
        pagination:this.$pagination
      },
    }
  },
  mounted() {
    this.searchAction();
  },
  methods:{
    init:function() {
      const self = this;
      this.search = {keyword: ''};
    },
    buildParams:function(){
      let params = Object.assign({'entity.keyword': this.search.keyword,'entity.status':'ENABLED'},
          { start: (this.grid.pagination.current - 1) * this.grid.pagination.pageSize,
            length: this.grid.pagination.pageSize});

      return params;
    },
    searchAction:function(){
      this.loadGrid(this.buildParams());
    },
    /**
     * 请求单页数据
     */
    loadGrid:function(params){
      const self = this;
      self.$axios.get('/api/admin/course/courses', {params: params}).then(function(res){
        if(res.msg.flag == 0){
          if(null == res.biz || res.biz.empty){
            Object.assign(self.grid, {items: [], pagination: Object.assign(self.grid.pagination, {current:1, total:0})});
            self.projects=[];
          }else{
            Object.assign(self.grid, {items: res.biz.data, pagination: Object.assign(self.grid.pagination, {total:res.biz.count})});
            self.projects=res.biz.data;
          }
        }else{
          console.info(res.msg.message);
        }
      },function(res){
        console.info(res);
      });
    },
    changePaginationSize:function (value){
      this.grid.pagination.pageSize = value;
      this.searchAction();
    },
    changePaginationCurrent:function (value) {
      console.log(value,123456789)
      this.grid.pagination.current = value;
      this.searchAction();
    },
    gotoVideoDetail:function (id){
      let path= this.$router.resolve({ path: '/video/player',query:{id:id}});
      window.open(path.href, '_blank');
    },

  }
}
</script>

<style scoped>
.course-title{
  font-size: 20px;
}
.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
}
</style>